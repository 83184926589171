.container {
    display: flex;
    width: 96%;
    /* padding: 1px; */
    margin-bottom: 1px;
    margin-left: 22px;
    margin-right: 22px;
    align-items: center;
    justify-content: center;
    /* border: 2px solid blue; */
    border-radius: 6px;
    /* background-color: red; */

}


.box30  {
    /* background-color :tomato; */
    height: auto;
    width: 30%;
}

.box30:hover{
    scale: 1.03;
    transition: .5s ease-in-out;
}

.box70  {
    text-align: left;
    /* background-color: red; */
    /* padding: 10px; */
    height: auto;
    width: 65%;
    font-size: 1.7rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hotizontal{
    height: 2px;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

/*
Khelo India Center */

@media only screen and (max-width: 902px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        padding: 4px;
        margin-left: 22px;
        margin-right: 22px;
        /* background-color: red; */

    }

    .box30  {
        /* background-color :tomato; */
        height: auto;
        width: 100%;
    }


.box70  {
    text-align: left;
    /* background-color: red; */
    padding: 10px;
    height: auto;
    width: 100%;
}


  }

