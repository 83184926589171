.loader_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #172027;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the loader is above other content */
  }
  
  .loader_container img {
    max-width: 100%; /* Adjust the size of the loader image */
    object-fit: cover;
  }
  