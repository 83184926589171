.title_container{
    /* border: 2px solid blue; */
    text-transform: uppercase;
    /* width: 283px; */
    height: auto;
    /* background-color: #01519a; */
    background-color: #fcb606;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    /* letter-spacing: 0.1em; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    left: 65px;
    /* z-index: 25; */
    transform-origin: bottom left;
    transform: rotate(-90deg) translateX(calc(-100% + 96px));

    /* padding: 20px 5px; */
    padding:10px 10px;
}







.title{
    /* border: 2px solid red; */
    position: relative;
    /* transform: rotate(-90deg) translateX(calc(-100% + 96px)); */
    /* top: -48.84px; */
    top: -28.84px;

}





.number{
    color: #fff;
    font-weight: 700;
    line-height: 1.2;
    font-family: "Montserrat", sans-serif;
    font-size: 55px;
    margin-bottom: 10px
}

.project_details{
     color: #fff;
      font-size: 18px
}











/* sponsors cards css */



.sponsore_card{
    /* border: 1px solid gray; */
    width:100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.sponsore_card :hover{

       transform: translateZ(10px);

       align-items: center;
       box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
       scale: 1.1;
       transition-duration: 1s;


}









/*
.footer-top-bg {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 700px;
    background-size: cover !important;
    height: auto;

    padding: 90px 0 90px;
    background-image: url("./img/parallax-footer.jpg");
  }
 */
