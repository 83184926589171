.container {
    display: flex;
    width: 95%;
    padding: 1px;
    margin-bottom: 10px;
    margin-left: 22px;
    margin-right: 22px;
    align-items: center;
    /* border: 2px solid blue; */
    border-radius: 6px;
    /* background-color: red; */

}
.box30  {
    /* background-color :tomato; */
    height: auto;
    width: 40%;
}

.box70  {
    text-align: left;
    /* background-color: red; */
    padding: 10px;
    height: auto;
    width: 55%;
    font-size: 1.7rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.textone{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
    /* width: 100%; */
    background:#252839
}


.textone h2{
position:relative ;
/* color: white; */
-webkit-text-stroke:0.1vw white ;
font-size: 5rem;

}

.textone h2::before{
    /* content: attr(data-text); */
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #01fe87;
    -webkit-text-stroke:0vw #383d52 ;
    border-right: 2px solid #01fe87;
    overflow: hidden;
    animation:animate 6s linear infinite;
}

@keyframes animate{

    0%, 10%, 110%{
        width: 0;
    }


    70%, 100%{
        width: 110%;
    }
}







@media only screen and (max-width: 902px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 95%;
        padding: 4px;
        margin-left: 22px;
        margin-right: 22px;
        /* background-color: red; */

    }

    .box30  {
        /* background-color :tomato; */
        height: auto;
        width: 100%;
    }


.box70  {
    text-align: left;
    /* background-color: red; */
    padding: 10px;
    height: auto;
    width: 100%;
}


  }