.title{
    margin-top: 50px;
    font-size: 24px;
    color: rgb(30, 30, 90);
    padding-bottom: 5rem;
}

.athlite_details{
display: flex;
justify-content: space-between;

}

.athlite_description{
    text-align: justify;
    font-size: 1.8rem;
    line-height: 3rem;
}
.athlite_img {
    margin-left: 3rem;
}

.athlite_img p{
    font-size: 24px;
    color: rgb(30, 30, 90);
    font-weight: 800;
   
    /* margin-right: 20rem; */
}