/* @font-face {
    font-family: "Grotesque";
    src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/BrandonGrotesque-Regular.eot"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/BrandonGrotesque-Regular.ttf"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/BrandonGrotesque-Regular.woff");
    font-weight: normal;
  }
  @font-face {
    font-family: "Grotesque Black";
    src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/BrandonGrotesque-Black.eot"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/BrandonGrotesque-Black.ttf"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/BrandonGrotesque-Black.woff");
    font-weight: bold;
  }
  * {
    box-sizing: border-box;
  }

  html,
  body {
    font-size: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    background: #152536;
  }
   */
  /* a[href] {
    position: relative;
  } */
  a[href], a[href]:link, a[href]:visited, a[href]:active {
    text-decoration: none;
    /* color: #d8276c; */
    /* text-shadow: 2px 2px 2px #070c11; */
    /* padding-bottom: 3px; */
    /* font-weight: bold; */
  }
  a[href]::after {
    content: "";
    position: absolute;
    left: 0;
    left: 0;
    bottom: 0;
    /* background: #fff; */
    width: 0;
    height: 1px;
    transition: 0.35s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  }
  a[href]:hover::after {
    width: 100%;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); */
  }

  .note {
    margin-top: 30px;
    color: #fff;
    font-size: 1rem;
    /* font-family: "Merriweather", sans-serif; */
    line-height: 1.5;
    text-align: center;
  }

  article.card {
    /* position: absolute; */
    /* left: 50%;
    top: 20%;
    left: 50%; */
    /* border:2px solid red; */
    /* width: 350px;
    height: 350px; */
    width: 100%;
    height: 200px;
    /* transform: translate(-50%, -50%) translateZ(0); */
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  article.card .thumb {
    width: auto;
    /* height: 260px; */
    height: 150px;
    /* background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/new-york-city.png") no-repeat center; */
    background-size: cover;
    border-radius: 3px;
  }
  article.card .infos {
    width: auto;
    /* height: 350px; */
    height: 300px;
    /* position: relative; */
    padding: 10px 24px;
    /* background: #e77410; */
    background:  #3da11f;
    align-items: center;
   
 
    transition: 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);

  }
  article.card .infos .title {
    /* position: relative; */
    /* margin: 4px 0; */
    /* letter-spacing: 3px; */
    /* color: #152536; */
    color: #fff;
    /* font-family: "Grotesque Black", sans-serif; */
    font-family: ' sans-serif';
    font-size: 1.4rem;
    text-transform: uppercase;
    text-shadow: 0 0 0px #32577f;
    line-height: 2rem;
  }
  article.card .infos .flag {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 35px;
    height: 23px;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/397014/flag.png") no-repeat top right;
    background-size: 100% auto;
    display: inline-block;
  }


  article.card .infos .date,
  article.card .infos .seats {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 0.85rem;
    color: rgba(21, 37, 54, 0.7);
    font-family: "Grotesque", sans-serif;
  }


  article.card .infos .seats {
    display: inline-block;
    /* margin-bottom: 24px; */
    /* padding-bottom: 24px; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  }
  article.card .infos .txt {
    font-family: "Merriweather", sans-serif;
    line-height: 2;
    font-size: 1.5rem;
    color: rgba(21, 37, 54, 0.7);
    opacity: 0;
    transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  }
  article.card .infos .details {
    /* position: absolute;
    left: 0;
    left: 0;
    bottom: 0; */
    margin: 20px 0;
    /* padding: 20px 24px; */
    letter-spacing: 1px;
     /* color: #4e958b; */
     color:#fff;
    /* font-family: "Grotesque Black", sans-serif;  */
    font-size: 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 0;
    transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  }
  article.card:hover .infos {
    /* transform: translateY(-260px); */
    /* transform: translateY(-130px); */
    transform: translateY(-150px);
    /* background: transparent; */
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    background:  rgba(247,159,83, 0.9);
    /* background: rgba(1,105,56, 0.9); */
   
    /* opacity: isHovered ? 1 : 0, */
    transition: opacity 0.3s;
    


  }


  article.card:hover .infos .seats,
  article.card:hover .infos .txt,
  article.card:hover .infos .details {
    opacity: 1;


    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
  


  }


  article.card .infos .title :hover{
      color:#fff;
  }