/* Page Layout */

.container {
    /* max-width: 850px; */
    margin: 50px auto;
    width:90%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}



/* Main Section */

.wrapper {
    display: flex;
}

.main {
    flex: 3;

}

.main h2 {
    font-size: 32px;
    /* margin: 1rem 0; */
    color: gold;
}

.main p {
    font-size: 1.6rem;
    margin-top: 2rem  ;
    /* margin-bottom:  .75rem; */
    text-align: left;

}



/* Sidebar */

.sidebar {
    padding: 20px;
    flex: 1;
    margin-right: 60px;
    border-top: 3px solid #016938;
    background-color: #f9f9f9;
}

.sidebar ul {
  list-style-type: none;
  margin-top: 1rem;
  font-size: 1.5rem;
}

.sidebar ul > li {
    padding: .25rem 0;
}




@media (max-width: 600px) {
    .wrapper {
        flex-direction: column;
    }

    .main {
        margin-right: 0;
        margin-bottom: 60px;
    }
}




.container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center	;
    justify-content: center;
    /* background-color: blue; */

  }

  .box30 {
    /* background-color: tomato; */
    margin: 1rem;
    height: 80px;
    width: 30%;
  }

  .box70 {
    /* background-color: red; */
    width: 100%;
    height: 100%;
    padding: 1rem;
    margin: 1rem;
    text-align: left;
    font-weight: 500;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width: 70%;
  }

  @media only screen and (max-width: 600px) {
    .container {
        /* display: flex; */
        width: 100%;
        flex-direction: column;
        background-color: blue;
      }

  }
















/* service card css */