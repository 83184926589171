.circle, .circle::before {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    color: yellow;
   content: "";
   margin: 15px;
   width: 29.3rem;
   height: 4.4rem;
   border-radius: 2%;      
   margin: 0 auto;
   transition: all 0.1s;
   background-color: red;
   opacity: .7;
   font-size: 2.8rem;
 }

 .circle::before {
   animation: mymove 1s infinite;
   position: absolute;
   background-color: green;
 }

 .circle:hover{
    cursor: pointer;
 }
.ghghgh{
  margin-top: 12px;
    position: absolute;
    left: 50%;
    z-index: 99;
}

@media only screen and (max-width: 680px) {
    .ghghgh {
      margin-top: 2px;
        left: 2%;
        top: 6rem;

    }
  }
 @keyframes mymove {
    80% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @-webkit-keyframes mymove {
    80% {
      -webkit-transform: scale(2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(2);
      opacity: 0;
    }
  }
