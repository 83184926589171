.video_container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }

  .video_container video {
    width: 100%;
    height: auto;
    /* display: block; */
  }

  .overlay_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    padding: 22%;
    color: white;
    max-width: 100%;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .overlay_content h3 {
    font-size: 100%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    /* margin: 0 0 10px; */
    color: black;
  }

  .overlay_content .carousel-date {
    display: block;
    margin-bottom: 10px;
  }

  .overlay_content .carousel-text {
    margin-bottom: 20px;
  }

  .overlay_content .button-link {
    color: white;
    text-decoration: none;
  }




  @media only screen and (max-width: 360px) {
    .overlay_content{
      justify-content: center;
      align-items: center;
    }
    .overlay_content h3{
      font-size: 100%;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      /* margin: 0 0 10px; */
      color: black;

    }

    .overlay_content p{
      font-size: 80%;
     

    }
  }
