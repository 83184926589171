/* .container{

    background-color: red;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content:center;
    align-items: center;
} */

/* .card{
    max-width: 400px;
    min-width: 200px;
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    height: 100%;

    background-color: white;

    margin: 20px;
    border-radius: 30px;
} */

/* body{
    min-height: 100vh;
    display: grid;
    place-items: center;
} */

.slider{
    height: 250px;
    padding: .4rem 0rem 0rem 0rem;
    margin: auto;
    position:relative;
    width: 90%;
    flex-shrink: 0;
    background:linear-gradient(to left, rgb(161, 116, 116) 0% ,rgba(70, 49, 49, 0) 0%);
    background-color: rgb(84, 82, 82);
    display: grid;
    place-items: center;
    /*  hide the extra image  */
    overflow: hidden;
    /* margin: 40px; */

}
.rop{
    height:5px;
    background-color: rgb(106, 105, 105);
    /* width: 100%; */
}

/*  setting up slider tract */

.slider_track{
    display: flex;
    height: 600px;
    background-color: white;

    width: calc(250px * 10); /*slide track width = totlal number of slides (9*2 = 18)* individual slide width(250px  */
    animation:  scroll 30s linear infinite;

}
@keyframes scroll {
    0%, 100% {
      transform: translateX(0); /* Reset translation at 0% and 100% keyframes */
    }
    25% {
      transform: translateX(calc(-300px)); /* Slide to the left at 25% */
    }
    50% {
      transform: translateX(calc(-600px)); /* Slide to the left at 50% */
    }
    /* Continue adding keyframes for each slide */
  }




.slide{
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    /*perspective to make the image move on z axis depth, we need to add to its container (.slide ) a perspective*/
    perspective:100px;
}

.slider_track:hover{
    animation-play-state: paused;
}

.same:hover{
    /* unlike translateX, Y and Z alone won't work */
    transform: translateZ(10px);
    transition: transform .5s;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* height: 250px; */
}

.gallerycontainer{
    /* background-color: gray; */
    padding: 4px 0px 0px 0px;
    margin-bottom: 3px;
/* background: linear-gradient(to top right, #87CEFA, #B0E0E6, #ffffff, #f0f0f0); */

  }

.slider img{
    width: 100% !important;
    transition: transform .5s !important;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */

}
/* image hover effect */

/* img:hover{ */
    /* unlike translateX, Y and Z alone won't work */
    /* transform: translateZ(10px);
    border: 4px solid blue;

} */



/* SHADOW  */

.slider::before,
.slider::after {
    /* background:linear-gradient(to right, rgba(255,255,255,1) 0% ,rgba(167, 118, 118, 0) 100%); */


    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
}

.slider::before{
    left: 0;
    top: 0;

}
.slider::after{
    right: 0;
    top: 0;
    transition: rotateZ(180deg);
}

.head{
    margin: 14px 0px 14px 0px ;
    color: rgb(11, 95, 95);

    font-size:x-large;
}


/* for different component */
.main{
    background: linear-gradient(to bottom right, #ffffff, #f0f0f0, #87CEFA, #B0E0E6);
    width: 100% ! important;
        padding: 1rem 1rem 5rem 1rem;
}

