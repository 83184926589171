.boxmodel ul li {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 14px;
    /* font-family: Roboto,Slab; */
    position: relative;
    margin-left: 20px; 
    display: inline-block;
    margin-right: 20px; 
  
    -moz-box-pack: center;
    justify-content: center;
    border: 1px solid green;
    text-align: center;
    padding: 10px;
}
.mainmenu{
    /* background: green;    */
    display: inline-block;
    border: 2px solid red;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 18px;
    /* color: white; */

}

