/* Common styles for table cells */
.table_cell {
  text-align: left;
  padding: 8px;
}
.th{
  background-color: lightpink;
  /* width: 10%; */
  text-align: left;
}
/* Image styles */


.table_image {
  height: 3rem;
  padding-left:2rem
}


.mySwiper{
  background-color: red;
}


.Container{
  margin:2% 5% 5% 5%
}

.input_group{
  width: 30%;
  padding: 0px 0px 5px 0px;
  /* margin-left: 70%; */
  align-content: end;
}


/* Media query for mobile view */
@media (max-width: 487px) {
  .table_cell {
    text-align: center;
    /* display: flex; */
    /* flex-direction: column; */
    font-size: 1rem;
    align-items: center;
  }
  .head{
      font-size: 1rem;
  }

  .table_image {
    margin-bottom: 0.5rem;
  }


}

@media (max-width: 487px) {
  .table_cell {
    text-align: center;
    /* display: flex; */
    /* flex-direction: column; */
    font-size: .8rem;
    align-items: center;
  }
  .head{
      font-size: .9rem;
  }

  .table_image {
    margin-bottom: 0.2rem;
    padding-left:0rem


  }


}

.tbls{
  display: flex;
  align-items: center;
  justify-content: center;
}

.resp{
  /* display: flex !important; */
  justify-content: center !important;
  align-items: center !important;
}

/* Define your media query for smaller screens, adjust the max-width as needed */
@media (max-width: 768px) {
  .resp {
    /* display: flex; */
    /* flex-direction: column !important; */
    align-items: center !important;
    font-size:10px !important;
    margin: 8px !important;
  }
}

@media (max-width: 1004px) {
  .tbls{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.tableCell{
    text-align: center; /* Center content horizontally */
    vertical-align: middle; /* Center content vertically */
}

@media (max-width: 488px) {
  .tableCell {
    /* display: flex; */
    /* flex-direction: column !important; */
    align-items: center !important;
    font-size:6px !important;
    margin: 8px !important;
  }
}
