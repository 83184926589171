
.card{
    border: 2px solid gray;
    --c: #016938;
    /* the border color */
    --b: 2px;
    /* the border thickness*/
    --g: 1px;
    /* the gap on hover */

    padding: calc(var(--g) + var(--b));
    --_g: #0000 25%, var(--c) 0;
    background:
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
      conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%)/var(--_i, var(--b)) 200% no-repeat;
    transition: .2s, background-position .2s .2s;
    cursor: pointer;
    height: 320px;
    width: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); /* Adding a box shadow */

}

.card:hover {
    --_i: 100%;
    transition: .2s, background-size .2s .2s;
    transform: scale(1.1)

}

.main{
    /* background-color: rgb(249, 250, 250); */
    width: 100%;
    height: 100%;
    padding: 8px 8px 80px 8px;
    /* margin:px */
}